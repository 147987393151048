import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import { RequiredItem } from "@transfr-inc/dashboard-components";

export function IntegrationIdp({ idpList, required = false, clearEnabled }) {
  const fieldTitle = "Integration IDP";
  const { control } = useFormContext();

  return (
    <div className="editor-section">
      <div className="section-title">
        <h2>{required ? <RequiredItem text={fieldTitle} /> : fieldTitle}</h2>
      </div>
      <Controller
        control={control}
        rules={{ required }}
        name="orgIdp"
        render={({ field: { value, onChange } }) => {
          return (
            <Dropdown
              className="idp-dropdown"
              selected={value}
              onOptionSelected={onChange}
            >
              <DropdownButton
                placeholder={"Select an IDP..."}
                icon={"fa-light fa-sitemap"}
                clearEnabled={clearEnabled}
              >
                {value?.name}
              </DropdownButton>
              <DropdownItems>
                {idpList?.map((idp) => (
                  <DropdownItem key={idp.id} value={idp} propName={"id"}>
                    {idp.name}
                  </DropdownItem>
                ))}
              </DropdownItems>
            </Dropdown>
          );
        }}
      />
    </div>
  );
}
