/* eslint-disable unicorn/consistent-destructuring */
import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { useForm, FormProvider } from "react-hook-form";

import {
  FullScreenModal,
  FullScreenModalBody,
  FullScreenModalHeader,
  FullScreenModalFooter,
  NotificationType,
  Loader,
} from "@transfr-inc/dashboard-components";
import { Product } from "@transfr-inc/dashboard-components/utils";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { formatDateDashes } from "@transfr-inc/dashboard-components/utils";

import { services } from "../../../dependencies";
import { OrgInfo } from "./org-info";
import { useApiRequest } from "../../../utils/http-client";

import "./new-org-modal.scss";

const newOrgFormDefaultValues = {
  organizationType: "",
  name: "",
};

export const NewOrgModal = ({ setShow, show, className, onOrgCreated }) => {
  // State variables & functions for dropdowns
  const [loading, setLoading] = useState();

  const [formInvalid, setFormInvalid] = useState(true);
  const [notification, setNotification] = useState();
  const [stateChildrenData, setStateChildrenData] = useState();
  const [stateParentData, setStateParentData] = useState();

  const {
    organizationService,
    careersService,
    menuBuilderService,
    trekService,
  } = services;

  const { response: trekCareers, sendRequest: getTrekCareers } = useApiRequest(
    () => trekService.getOrganizationTreks(),
    false
  );

  const { response: sims, sendRequest: getSimulations } = useApiRequest(
    () => careersService.getAllSimulations(),
    false
  );

  const { response: tsMenus, sendRequest: getTSMenus } = useApiRequest(
    () => menuBuilderService.getAllMenus(),
    false
  );

  const { response: idpList = [], sendRequest: getIdpList } = useApiRequest(
    () => organizationService.getIdpList(),
    false
  );

  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
    defaultValues: newOrgFormDefaultValues,
  });

  const onClose = () => {
    reset(newOrgFormDefaultValues);
    setShow(false);
  };

  // refs:
  // https://react-hook-form.com/api/useform/formstate
  // https://react-hook-form.com/advanced-usage#FormProviderPerformance
  //
  // Although formState is not used in this component, we still need to "subscribe" to it due to the following mention
  // in the first link above:
  // "formState is wrapped with a Proxy to improve render performance and skip extra logic if specific state is not
  // subscribed to. Therefore make sure you invoke or read it before a render in order to enable the state update."
  // The second link demonstrates sample usage with FormProvider that we are employing here.

  const { handleSubmit, reset } = methods;

  methods.formState.errors;
  methods.formState.isValid;
  methods.formState.dirtyFields;

  async function fetchOrgs() {
    const [childredData, parentData] = await Promise.all([
      organizationService.getSingleOrgs(),
      organizationService.getParentOrgs(),
    ]);
    getTSMenus();
    getSimulations();
    getTrekCareers();
    getIdpList();
    setStateChildrenData(childredData);
    setStateParentData(parentData);
    setLoading();
  }

  async function submitForm(values, e) {
    e.preventDefault();
    setLoading(true);

    let returnValue = false;
    let productIds = [];
    try {
      const org = {
        name: values.name,
        orgTypeId: values.organizationType.id,
      };
      if (values.children) {
        org.children = values.children.map((child) => child.data.id);
      }
      if (values.parent) {
        org.parent = values.parent.id;
      }
      if (values.traineeLimit) {
        org.traineeLimit = Number.parseInt(values.traineeLimit);
      }
      if (values.trekLicenses) {
        org.trekLicenses = Number.parseInt(values.trekLicenses);
      }
      if (values.expiration) {
        org.expiration = formatDateDashes(values.expiration);
      }
      if (values.products) {
        productIds = values.products.map((product) => product.id);
        org.products = productIds;
      }
      if (values.menu) {
        org.menu = values.menu.id;
      }
      if (values.allSims !== undefined) {
        const { allSims, simulations } = values;
        org.allSims = allSims;
        org.sims = allSims ? [] : simulations?.map((d) => d.id);
      }
      if (values.studentExperience !== undefined) {
        org.studentExperience = values.studentExperience;
      }

      if (values.integrations !== undefined) {
        org.integrations = values.integrations;
      }

      if (values.missionControl !== undefined) {
        org.missionControl = values.missionControl;
      }

      if (values.classroomMenuBuilder !== undefined) {
        org.classroomMenuBuilder = values.classroomMenuBuilder;
      }

      if (values.integrationExternalId) {
        org.externalOrgCodes = [values.integrationExternalId];
      }

      if (values.orgIdp?.id) {
        org.orgIdpId = values.orgIdp?.id;
      }

      const orgCreated = await organizationService.createOrg(org);

      if (org.products?.includes(Product.TRK) && orgCreated) {
        await trekService.upsertOrganizationCareers(orgCreated.code, {
          hasAllCareers: values.allCareers,
          careerIds: values.careers ? values.careers.map((p) => p.id) : [],
        });
      }

      const notification = {
        type: NotificationType.success,
        message: "Organization successfully created",
      };

      await onOrgCreated({
        notification,
        organization: { ...org, code: orgCreated?.code },
        parentOrg: values.parent,
      });
      setLoading();
      returnValue = true;
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.data.detail ?? "Unable to create organization.";
      setNotification({
        type: NotificationType.error,
        message: errorMessage,
      });
      returnValue = false;
    }
    setLoading();
    return returnValue;
  }

  const onClick = (e) => {
    (async () => {
      handleSubmit(submitForm)(e);
    })();
  };

  useEffect(() => {
    setFormInvalid(!methods.formState.isValid);
  }, [methods.formState.isValid]);

  useEffect(() => {
    if (show) {
      reset(newOrgFormDefaultValues);
      setLoading(true);
      fetchOrgs();
    }
  }, [show]);

  return (
    <div className={clsx("new-organization-modal", className)}>
      <FullScreenModal onClose={onClose} open={show} preventClose>
        <FullScreenModalHeader
          title="Create a New Organization"
          titleIcon="fa-regular fa-sitemap"
        />
        <FullScreenModalBody>
          <div className="new-org">
            {loading && <Loader overlay></Loader>}
            <div className="text-with-info">
              <label>
                Required Fields<sup>*</sup>
              </label>
              <div className="organization-info">
                <FormProvider {...methods}>
                  <form
                    className={clsx("org-form", className)}
                    noValidate={true}
                  >
                    <OrgInfo
                      responseNotification={notification}
                      onNotificationChange={setNotification}
                      childrenData={stateChildrenData}
                      parentData={stateParentData}
                      tsMenus={tsMenus}
                      sims={sims}
                      trekCareers={trekCareers}
                      idpList={idpList}
                    ></OrgInfo>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </FullScreenModalBody>
        <FullScreenModalFooter>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button primary disabled={formInvalid || loading} onClick={onClick}>
            Create Organization
          </Button>
        </FullScreenModalFooter>
      </FullScreenModal>
    </div>
  );
};

export default NewOrgModal;
