import React from "react";
import clsx from "clsx";

import { ProductsTabs } from "./products.tabs";

import "./products.section.scss";

export const ProductsSection = ({ organization, className }) => {
  const { products } = organization || {};

  return (
    <div>
      <div className={clsx("products-section", className)}>
        <h2>
          Products <span className="badge">{products?.length}</span>{" "}
        </h2>
        <div className="box-section">
          <ProductsTabs organization={organization}></ProductsTabs>
        </div>
      </div>
    </div>
  );
};
