import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import {
  formatDateDashes,
  getUTCDate,
  Product,
} from "@transfr-inc/dashboard-components/utils";

import {
  getCareerOptions,
  getProductsOptions,
  getSimulationsOptions,
  OrgDetailsEditor,
  OrgExpirationDateEditor,
  OrgFormEditor,
  OrgFormToggle,
  OrgIntegrationExtIdEditor,
  OrgProductsEditor,
  OrgSelectMenuEditor,
  OrgSelectParentEditor,
  OrgSimsEditor,
  OrgTraineeLimitEditor,
  OrgTrekCareersEditor,
  OrgTrekLicensesEditor,
  IntegrationIdp,
} from "../../forms/org-editor";

import { MULTI_ORG_PARENT } from "../../../constants/organization-types";
import { OrganizationContext } from "../../../context";
import { services } from "../../../dependencies";
import { useApiRequest } from "../../../utils/http-client";

import "./edit-org.modal.scss";

export default ({
  open,
  onClose,
  organization,
  onUpdateOrg,
  onParentChange,
}) => {
  const [areCareersLoaded, setAreCareersLoaded] = useState();
  const { updateOrganizations } = useContext(OrganizationContext);
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showTSMenu, setShowTSMenu] = useState();
  const [showSims, setShowSims] = useState();
  const [showTrek, setShowTrek] = useState();
  const [showCareers, setShowCareers] = useState();
  const [showStudentExperienceToggle, setShowStudentExperienceToggle] =
    useState();
  const methods = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });
  const { reset, handleSubmit, formState, watch } = methods;
  const {
    organizationService,
    menuBuilderService,
    careersService,
    trekService,
  } = services;
  const productsWatch = watch("products");

  const { response: parentOrgs = [], sendRequest: getParentOrgs } =
    useApiRequest(() => organizationService.getParentOrgs(), false);

  const { response: idpList = [], sendRequest: getIdpList } = useApiRequest(
    () => organizationService.getIdpList(),
    false
  );

  const {
    response: orgMenu,
    resetResponse: resetOrgMenu,
    sendRequest: getOrgMenu,
  } = useApiRequest(
    () => organizationService.getOrganizationMenu(organization?.code),
    false
  );

  const { response: tsMenus, sendRequest: getTSMenus } = useApiRequest(
    () => menuBuilderService.getAllMenus(),
    false
  );

  const {
    response: orgTrekCareers,
    resetResponse: resetOrgTrekCareers,
    sendRequest: getOrgTrekCareers,
  } = useApiRequest(() => trekService.getCareers(organization?.code), false);

  const { response: trekCareers, sendRequest: getTrekCareers } = useApiRequest(
    () => trekService.getCareers(),
    false
  );

  const {
    response: orgSimulations,
    resetResponse: resetOrgSimulations,
    sendRequest: getOrgSimulations,
  } = useApiRequest(
    () => organizationService.getOrganizationSimulations(organization?.code),
    false
  );

  const { response: sims = [], sendRequest: getSimulations } = useApiRequest(
    () => careersService.getAllSimulations(),
    false
  );

  const loadTrekCareers = async () => {
    setAreCareersLoaded();

    await Promise.allSettled([getTrekCareers(), getOrgTrekCareers()]);
    setAreCareersLoaded(true);
  };

  const onConfirmChanges = () => {
    setLoading(true);
    setErrorMessage();

    handleSubmit(async (values) => {
      const dataUpdated = getUpdateData(values);
      try {
        const updatedOrg = await organizationService.updateOrgMetadata(
          dataUpdated
        );

        if (values.products.map((p) => p.id).includes(Product.TRK)) {
          await trekService.upsertOrganizationCareers(organization.code, {
            hasAllCareers: values.allCareers,
            careerIds: values.careers ? values.careers.map((p) => p.id) : [],
          });
        }

        updateOrganizations();
        onUpdateOrg && onUpdateOrg(updatedOrg);
        if (onParentChange) {
          onParentChange(values.parent);
        }
      } catch (error) {
        const message =
          error.data?.detail ?? "Something went wrong. Please try again.";
        setErrorMessage(message);
        console.error(
          "[Edit Org] - Something went wrong. Error Details >>>",
          error
        );
      } finally {
        setLoading();
      }
    })();
  };

  const getUpdateData = (data) => {
    const orgUpdated = { code: organization.code, name: data.name };

    if (organization.typeId !== MULTI_ORG_PARENT.id) {
      orgUpdated.traineeLimit =
        data.seats >= 0 && Number.parseInt(data.seats || 0);
      orgUpdated.trekLicenses =
        data.trekLicenses >= 0 && Number.parseInt(data.trekLicenses || 0);
      orgUpdated.instructorSeats = organization.instructorSeats;
      orgUpdated.expiration =
        data.expiration && formatDateDashes(data.expiration);
      orgUpdated.children = data?.children && data.children.map((c) => c.id);
      orgUpdated.parent = data.parent?.id;
      orgUpdated.products = data.products && data.products.map((p) => p.id);
      orgUpdated.studentExperience = data.studentExperience;
      orgUpdated.classroomMenuBuilder = data.classroomMenuBuilder;
      orgUpdated.menu = data.menu && data.menu.id;
      orgUpdated.allSims = data.allSims;
      orgUpdated.simulations = data?.simulations
        ? data.simulations.map((s) => s.id)
        : [];
      orgUpdated.integrations = data.integrations;
      orgUpdated.missionControl = data.missionControl;
      if (data.integrationExternalId) {
        orgUpdated.externalOrgCodes = [data.integrationExternalId];
      }
      orgUpdated.orgIdpId = data.orgIdp?.id;
    }

    return orgUpdated;
  };

  const resetForm = () => {
    reset({
      ...organization,
      expiration: organization.expiration
        ? getUTCDate(organization.expiration)
        : undefined,
      products: getProductsOptions(organization.products),
      parent: organization.parent,
      menu: orgMenu,
      simulations: getSimulationsOptions(orgSimulations?.simulations),
      allSims: orgSimulations?.allSims,
      careers: getCareerOptions(orgTrekCareers),
      allCareers: trekCareers?.length == orgTrekCareers?.length,
      studentExperience: organization.studentExperience,
      classroomMenuBuilder: organization.classroomMenuBuilder,
      integrations: organization.integrations,
      missionControl: organization.missionControl,
      integrationExternalId: organization.orgExternalIds?.length
        ? organization.orgExternalIds[0]
        : "",
      orgIdp: organization.orgIdp,
    });

    if (organization?.typeId !== MULTI_ORG_PARENT.id) {
      getParentOrgs();
      getTSMenus();
      getSimulations();
      getIdpList();
    }
  };

  const resetOrganizationCustomData = () => {
    resetOrgMenu();
    resetOrgTrekCareers();
    resetOrgSimulations();
  };

  useEffect(() => {
    if (organization?.typeId === MULTI_ORG_PARENT.id) {
      resetForm();
    } else if (organization?.code) {
      setLoading(true);
      getOrgMenu();
      getOrgSimulations();
      // To indicate that an organization has all the Trek careers or a subset of them,
      // we need both responses to display the correct view.
      loadTrekCareers();
    }
  }, [organization]);

  useEffect(() => {
    if (organization?.code) {
      setLoading();
      resetForm();
    }
  }, [orgMenu, orgSimulations, areCareersLoaded]);

  useEffect(() => {
    setShowTSMenu(productsWatch?.find((p) => p.id === Product.TS));
    setShowSims(productsWatch?.find((p) => p.id === Product.CE));
    setShowTrek(productsWatch?.find((p) => p.id === Product.TRK));
    setShowCareers(productsWatch?.find((p) => p.id === Product.TRK));
    setShowStudentExperienceToggle(
      productsWatch?.find((p) => p.id === Product.TS || p.id === Product.CE)
    );
  }, [productsWatch]);

  useEffect(() => {
    reset({});
    // When closing the modal, it doesn't reset the custom data from the organization
    // because the modal is being hidden instead of unmounted.
    // This can cause confusion when opening the modal with a new organization and previous data loaded.
    resetOrganizationCustomData();
  }, [open]);

  return (
    <Modal
      modalClassName={"edit-org-modal"}
      open={open}
      onClose={onClose}
      uniqueName="edit-org-modal"
    >
      {loading && <Loader overlay />}
      <ModalHeader
        className="blue-icon small"
        icon={["fa-regular", "edit"]}
        title="Edit Organization"
      ></ModalHeader>

      <ModalBody className="modal-org-details">
        <FormProvider {...methods}>
          <OrgFormEditor>
            {errorMessage && (
              <Notification
                animated
                closable
                onClose={() => setErrorMessage()}
                type={NotificationType.error}
              >
                {errorMessage}
              </Notification>
            )}
            <OrgDetailsEditor></OrgDetailsEditor>
            {organization?.typeId !== MULTI_ORG_PARENT.id && (
              <>
                <OrgProductsEditor
                  placeholder={"Select one or more products..."}
                ></OrgProductsEditor>
                {showStudentExperienceToggle && (
                  <OrgFormToggle
                    name="studentExperience"
                    title="Student Experience"
                    infoText="Would you like students to have the option to view progress through their own dashboard?"
                  ></OrgFormToggle>
                )}
                {showTSMenu && (
                  <div>
                    <OrgSelectMenuEditor menus={tsMenus} isEdit />
                    <OrgFormToggle
                      name="classroomMenuBuilder"
                      title="Classroom Menu Builder"
                      infoText="Would you like instructors to be able to create a custom menu for their classroom?"
                    ></OrgFormToggle>
                  </div>
                )}
                {showSims && (
                  <OrgSimsEditor required data={sims}></OrgSimsEditor>
                )}
                {showCareers && (
                  <OrgTrekCareersEditor
                    required
                    data={trekCareers}
                  ></OrgTrekCareersEditor>
                )}
                <OrgSelectParentEditor
                  orgs={parentOrgs}
                  clearEnabled
                ></OrgSelectParentEditor>
                <IntegrationIdp idpList={idpList} />
                <OrgIntegrationExtIdEditor></OrgIntegrationExtIdEditor>
                <OrgFormToggle
                  className="edit-org-integrations"
                  name="integrations"
                  title="Integrations"
                  infoText="Would you like to enable external integrations?"
                ></OrgFormToggle>
                <OrgFormToggle
                  className="edit-org-mission-control"
                  name="missionControl"
                  title="Mission Control (CE/TREK)"
                  infoText="Would you like to enable Mission Control?"
                ></OrgFormToggle>
                <OrgTraineeLimitEditor></OrgTraineeLimitEditor>
                {showTrek && <OrgTrekLicensesEditor name="trekLicenses" />}
                <OrgExpirationDateEditor></OrgExpirationDateEditor>
              </>
            )}
          </OrgFormEditor>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          primary
          onClick={onConfirmChanges}
          disabled={!formState.isValid}
        >
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};
