import React from "react";

export const AppContext = React.createContext({});

export default ({ children }) => {
  const contextValue = {};
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
