import {
  AuthenticationService,
  CareersService,
  ClassroomService,
  DisciplinesService,
  MenuBuilderService,
  OrganizationService,
  TrekService,
  UserService,
} from "./services";
import { LocalStorageCache } from "./utils/local-storage-cache";

/* Poor mans DI */
export const caches = {
  userCache: new LocalStorageCache("app/dashboard/currentUser", 86400),
  tokenCache: new LocalStorageCache("app/dashboard/token", 86400),
};

/* Poor mans DI */
export const toggles = {};

/* Poor mans DI */
export const services = {
  authenticationService: new AuthenticationService(
    process.env.DASHBOARD_API_URL,
    { publicPaths: ["/auth/login", "/auth/reset"] },
    caches
  ),
  userService: new UserService(process.env.DASHBOARD_API_URL, {}, caches),
  organizationService: new OrganizationService(
    process.env.DASHBOARD_API_URL,
    {},
    caches
  ),
  classroomService: new ClassroomService(
    process.env.DASHBOARD_API_URL,
    {},
    caches
  ),
  disciplinesService: new DisciplinesService(
    process.env.DASHBOARD_API_URL,
    {},
    caches
  ),
  careersService: new CareersService(process.env.DASHBOARD_API_URL, {}, caches),
  menuBuilderService: new MenuBuilderService(
    process.env.DASHBOARD_API_URL,
    {},
    caches
  ),
  trekService: new TrekService(process.env.CESE_API_URL, {}, caches),
};
