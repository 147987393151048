import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import { Input } from "@transfr-inc/dashboard-components/forms";

export function OrgIntegrationExtIdEditor({
  className,
  name = "integrationExternalId",
}) {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { orgIdp } = watch();

  return (
    <div className={clsx("editor-section", className)}>
      <div className="section-title">
        <h2>Integration External ID</h2>
      </div>
      <Controller
        control={control}
        name={name}
        rules={{ required: false, maxLength: 150 }}
        defaultValue={""}
        render={({ field: { onBlur, onChange, value, ref } }) => {
          return (
            <Input
              disabled={!orgIdp?.id}
              className="integration-ext-id-input"
              label="Used for 3rd Party LMS Integration"
              errors={errors[name]}
              name={name}
              ref={ref}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              maxLength={150}
            />
          );
        }}
      />
    </div>
  );
}
