import React, { useContext, useEffect, useState } from "react";

import { Notification } from "@transfr-inc/dashboard-components";

import { services } from "../dependencies";
import { useApiRequest } from "../utils/http-client";
import { SessionContext } from "./session.context";
import { sortObjectArrayByKey } from "../utils/utils";

export const MenuBuilderContext = React.createContext({});

export default ({ children }) => {
  const [menus, setMenus] = useState();
  const [categories, setAllCategories] = useState();
  const [modules, setModules] = useState();
  const [globalNotification, setGlobalNotification] = useState();

  const { menuBuilderService } = services;
  const { currentUser } = useContext(SessionContext);

  useEffect(() => {
    if (currentUser) {
      setMenus([]);
      getAllCategories();
      getAllModules();
    }
  }, [currentUser]);

  const { loading, sendRequest: getAllModules } = useApiRequest(() =>
    menuBuilderService
      .getAllModules()
      .then((response) => setModules(sortObjectArrayByKey(response, "name")))
      .catch((error) => {
        console.error("ERROR:", error);
        return;
      })
  );

  const { loading: loadingCategories, sendRequest: getAllCategories } =
    useApiRequest(() =>
      menuBuilderService
        .getTemplateCategories(false)
        .then((response) => {
          setAllCategories(sortObjectArrayByKey(response, "name"));
        })
        .catch((error) => {
          console.error("ERROR:", error);
          return;
        })
    );

  const contextValue = {
    currentUser,
    modules,
    menus,
    categories,
    loadingAllCategories: loadingCategories,
    loadingAllModules: loading,
    getAllCategories: getAllCategories,
    getAllModules: getAllModules,
    setGlobalNotification: setGlobalNotification,
  };

  return (
    <MenuBuilderContext.Provider value={contextValue}>
      {children}
      <div className="notification-container success-notification">
        {globalNotification && (
          <Notification
            type={globalNotification.type}
            icon={globalNotification.icon}
            onClose={() => {
              setGlobalNotification();
            }}
            closable
            animated
          >
            {globalNotification.message}
          </Notification>
        )}
      </div>
    </MenuBuilderContext.Provider>
  );
};
