import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";
import { RequiredItem } from "@transfr-inc/dashboard-components";
import { Product } from "@transfr-inc/dashboard-components/utils";

import {
  MULTI_ORG_PARENT,
  MULTI_ORG_CHILD,
} from "../../../constants/organization-types";

import {
  OrgExpirationDateEditor,
  OrgProductsEditor,
  OrgSelectMenuEditor,
  OrgTraineeLimitEditor,
  OrgSimsEditor,
  OrgFormToggle,
  OrgTrekLicensesEditor,
  OrgTrekCareersEditor,
  OrgIntegrationExtIdEditor,
  IntegrationIdp,
} from "../../../components/forms/org-editor";
import { SingleOrgSelect } from "../../../components/forms/single-org-select";

export function OrgDetailEditor({
  childrenData,
  parentData,
  tsMenus,
  sims,
  trekCareers,
  selectedType,
  idpList,
}) {
  const [showTSMenu, setShowTSMenu] = useState();
  const [showSims, setShowSims] = useState();
  const [showTrek, setShowTrek] = useState();
  const [showTrekSelection, setShowTrekSelection] = useState();
  const { control, setValue, watch } = useFormContext();
  const productsWatch = watch("products");

  const onOrgSelected = async (org) => {
    setValue("parent", org);
  };

  useEffect(() => {
    setShowTSMenu(productsWatch?.find((p) => p.id === Product.TS));
    setShowSims(productsWatch?.find((p) => p.id === Product.CE));
    setShowTrek(productsWatch?.find((p) => p.id === Product.TRK));
    setValue("allSims", true);
    setShowTrekSelection(productsWatch?.find((p) => p.id === Product.TRK));
    setValue("allCareers", true);
  }, [productsWatch]);

  return (
    <div className="org-detail-editor">
      {selectedType.id == MULTI_ORG_PARENT.id ? (
        <div>
          <div className="section-title">Organization&apos;s Children</div>
          <Controller
            control={control}
            name="children"
            defaultValue={[]}
            render={({ field: { onChange, value } }) => {
              return (
                <SingleOrgSelect
                  selectedOrgs={value}
                  onChange={onChange}
                  placeholder={
                    "Select one or more existing child organizations..."
                  }
                  data={childrenData}
                ></SingleOrgSelect>
              );
            }}
          />
        </div>
      ) : (
        <div>
          {selectedType.id == MULTI_ORG_CHILD.id && (
            <>
              <div className="section-title">
                <RequiredItem text="Organization's Parent" />
              </div>
              <Controller
                control={control}
                rules={{ required: true }}
                name="parent"
                render={({ field: { value } }) => {
                  return (
                    <Dropdown
                      className="org-dropdown"
                      selected={value}
                      onOptionSelected={onOrgSelected}
                    >
                      <DropdownButton
                        placeholder={"Select an organization..."}
                        icon={"fa-light fa-sitemap"}
                      >
                        {value?.name}
                      </DropdownButton>
                      <DropdownItems>
                        {parentData.map((org) => (
                          <DropdownItem key={org.code} value={org}>
                            {org.name}
                          </DropdownItem>
                        ))}
                      </DropdownItems>
                    </Dropdown>
                  );
                }}
              />
            </>
          )}
          <OrgProductsEditor></OrgProductsEditor>
          {showTSMenu && (
            <div>
              <OrgSelectMenuEditor menus={tsMenus} />
              <OrgFormToggle
                name="studentExperience"
                title="Student Experience"
                infoText="Would you like students to have the option to view progress through their own dashboard?"
              ></OrgFormToggle>
              <OrgFormToggle
                name="classroomMenuBuilder"
                title="Classroom Menu Builder"
                infoText="Would you like instructors to be able to create a custom menu for their classroom?"
              ></OrgFormToggle>
            </div>
          )}
          <OrgFormToggle
            name="integrations"
            title="Integrations"
            infoText="Would you like to enable external integrations?"
          ></OrgFormToggle>
          <IntegrationIdp idpList={idpList} />
          <OrgIntegrationExtIdEditor></OrgIntegrationExtIdEditor>
          <OrgFormToggle
            name="missionControl"
            title="Mission Control (CE/TREK)"
            infoText="Would you like to Mission Control?"
          ></OrgFormToggle>
          {showSims && <OrgSimsEditor required data={sims}></OrgSimsEditor>}
          {showTrekSelection && (
            <OrgTrekCareersEditor
              required
              data={trekCareers}
            ></OrgTrekCareersEditor>
          )}
          <OrgTraineeLimitEditor name="traineeLimit"></OrgTraineeLimitEditor>
          {showTrek && <OrgTrekLicensesEditor name="trekLicenses" />}
          <OrgExpirationDateEditor></OrgExpirationDateEditor>
        </div>
      )}
    </div>
  );
}
